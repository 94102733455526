<template>
  <component :is="component" v-bind="linkProps" class="c-bitts-link">
    {{ text
    }}<FontAwesomeIcon
      v-if="externalLink || showArrow"
      :icon="['far', 'arrow-up-right']"
      :style="{ height: '14px', width: '14px' }"
      class="bitts-link__icon ml-4"
    />
  </component>
</template>

<script lang="ts">
import { computed } from 'vue';

export interface BittsLinkProps {
  text: string;
  url?: string;
  openInNewTab?: boolean;
  /** An arrow will be shown by default for all external links. */
  showArrow?: boolean;
}
</script>
<script setup lang="ts">
const props = withDefaults(defineProps<BittsLinkProps>(), {
  url: undefined,
  openInNewTab: undefined,
  showArrow: false,
});

const externalLink = computed(() => {
  if (props.url) {
    try {
      const externalUrl = new URL(props.url);
      if (externalUrl.href) return true;
    } catch (_) {
      return false;
    }
  }
  return false;
});

const linkProps = computed(() =>
  externalLink.value
    ? { href: props.url, target: props.openInNewTab ? '_blank' : '_self' }
    : { to: { name: props.url } },
);

const component = computed(() => {
  if (props.url === '#') return 'button';
  return externalLink.value ? 'a' : 'router-link';
});
</script>

<style lang="pcss" scoped>
.c-bitts-link {
  @apply cursor-pointer items-center text-secondary-text inline-block rounded-4;

  .bitts-link__icon {
    @apply text-secondary-accent;
  }

  &:focus-visible {
    @apply outline-none outline-2 outline-neutral-border-focus;
  }

  &:focus,
  &:hover {
    @apply text-primary-text-link-hover;
    .bitts-link__icon {
      @apply text-primary-text-link-hover;
    }
  }

  &:hover {
    @apply text-primary-text-link-hover;
    .bitts-link__icon {
      @apply text-primary-text-link-hover;
    }
  }
}
</style>
